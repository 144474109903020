@font-face {
    font-family: 'AltGeorgia';
    src: url(../fonts/Georgia.eot);
    src: url(../fonts/Georgia.eot?#iefix) format('embedded-opentype'),
         url(../fonts/Georgia.woff) format('woff'),
         url(../fonts/Georgia.ttf) format('truetype'),
         url(../fonts/Georgia.svg#Georgia) format('svg');
   font-style: normal;
   font-weight: normal;
}

@font-face {
  font-family: 'AltGeorgiaBold';
    src: url(../fonts/GeorgiaBold.eot);
    src: url(../fonts/GeorgiaBold.eot?#iefix) format('embedded-opentype'),
         url(../fonts/GeorgiaBold.woff) format('woff'),
         url(../fonts/GeorgiaBold.ttf) format('truetype'),
         url(../fonts/GeorgiaBold.svg#Georgia) format('svg');
}

@font-face {
  font-family: 'AltGeorgiaItalic';
    src: url(../fonts/GeorgiaItalic.eot);
    src: url(../fonts/GeorgiaItalic.eot?#iefix) format('embedded-opentype'),
         url(../fonts/GeorgiaItalic.woff) format('woff'),
         url(../fonts/GeorgiaItalic.ttf) format('truetype'),
         url(../fonts/GeorgiaItalic.svg#Georgia) format('svg');
}

@font-face {
  font-family: 'AltGeorgiaBoldItalic';
    src: url(../fonts/GeorgiaBoldItalic.eot);
    src: url(../fonts/GeorgiaBoldItalic.eot?#iefix) format('embedded-opentype'),
         url(../fonts/GeorgiaBoldItalic.woff) format('woff'),
         url(../fonts/GeorgiaBoldItalic.ttf) format('truetype'),
         url(../fonts/GeorgiaBoldItalic.svg#Georgia) format('svg');
}

* {
    box-sizing: border-box;
}

body {
    font-family: AltGeorgia;
    /*font-family: "Georgia", "Times", "Times New Roman", "serif";*/
    color: #566b78;
    margin: 0;
}

strong {
    font-style: normal;
    font-family: AltGeorgiaBold;
}

em {
    font-style: normal;
    font-family: AltGeorgiaItalic;
}

strong em, em strong {
    font-style: normal;
    font-family: AltGeorgiaBoldItalic;
}

main {
    max-width: 50em;
    line-height: 1.5;
    padding: 2em 1em 4em 1em;
    margin: 0 auto;
}

nav a {
    text-decoration: none;
}

section {
    position: relative;
    color: #566b78;
}

div.full {
    display: inline-block;
    clear: both;
    width: 100%;
    & + section {
        & {
            & h1,
            & h2,
            & h3,
            & h4,
            & h5,
            & h6 {
                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
    font-style: normal;
    font-family: AltGeorgiaBold;
    font-weight: inherit;
    color: #333;
}
h2 {
    margin-top: 1em;
    padding-top: 1em;
}

a,
a > * {
    color: #e81c4f;
}

p a strong {
    color: #e81c4f;
}

img.img10em {
    width: 10em;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.centre {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pad10 {
    padding: 10px !important;
}

.pad10-r {
    padding-right: 10px !important;
}

.pad10-l {
    padding-left: 10px !important;
}

.pad10-t {
    padding-top: 10px !important;
}

.pad10-b {
    padding-bottom: 10px !important;
}

.pad10-v {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.pad10-h {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.mar10 {
    margin: 10px !important;
}

.mar10-r {
    margin-right: 10px !important;
}

.mar10-l {
    margin-left: 10px !important;
}

.mar10-t {
    margin-top: 10px !important;
}

.mar10-b {
    margin-bottom: 10px !important;
}

.mar10-v {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.mar10-h {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.white-background {
    background: #FFF;
}

.limit {
    max-width: 100%;
}

.limit div > *,
.limit > * {
    max-width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

tr {
    border: 0px solid #EEE;
    border-bottom-width: 1px;
}

tbody tr:hover {
    background: #EEE;
}

th {
    text-align: left;
}

th, td {
    border: 0;
}

address {
    border: 0 solid aliceblue;
    border-left-width: 2px;
    padding: 0 10px;
}

blockquote {
    float: left;
    background: #f9f9f9;
    font-style: italic;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    margin-bottom: 0;
    margin-top: 10px;
    &:first-of-type {
        display: inline;
    }
}

blockquote small {
    margin-top: 10px;
    float: right;
    width: 70%;
    color: #999;
    text-align: right;
}

span.cite {
    border-bottom: 1px dashed;
    cursor: help;
}

div.remember,
div.myth,
div.hypothesis,
div.theory,
div.fact,
div.subtle {
    display: table;
    // float: left;
    // display: block;
    // margin-top: 20px;
    padding: 10px;
    margin: 0 -1em;

    margin-top: 30px;
    margin-bottom: 30px;
    // padding: 10px 0;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
    position: relative;

    width: 100%;
    clear: both;

    &.float {
        width: 100%;
    }

    & + p {
        clear: both;
    }

    .image-frame {
        // background: blue;
        display: table-row;
    }
}

div.remember strong,
div.myth strong,
div.hypothesis strong,
div.theory strong,
div.fact strong,
div.subtle strong {
    /*color: inherit !important;*/
}

div.remember > p:last-of-type,
div.myth > p:last-of-type,
div.hypothesis > p:last-of-type,
div.theory > p:last-of-type,
div.fact > p:last-of-type,
div.subtle > p:last-of-type {
    margin-bottom: 0;
}


div.remember h1:first-child,
div.remember h2:first-child,
div.remember h3:first-child,
div.remember h4:first-child,
div.myth h1:first-child,
div.myth h2:first-child,
div.myth h3:first-child,
div.myth h4:first-child,
div.hypothesis h1:first-child,
div.hypothesis h2:first-child,
div.hypothesis h3:first-child,
div.hypothesis h4:first-child,
div.theory h1:first-child,
div.theory h2:first-child,
div.theory h3:first-child,
div.theory h4:first-child,
div.fact h1:first-child,
div.fact h2:first-child,
div.fact h3:first-child,
div.fact h4:first-child,
div.subtle h1:first-child,
div.subtle h2:first-child,
div.subtle h3:first-child,
div.subtle h4:first-child {
    /*margin-top: 0;*/
    color: inherit !important;
    margin: 0;
    position: absolute;
    top: -1em; /*-13*/
    background: white;
    padding: 3px 5px 2px 5px;
    border: 1px solid;
}

div.remember {
    /*background: #f9f9FF;*/
    /*color: #566b78;*/
    border-color: #566b78;
}

div.remember h1:first-child,
div.remember h2:first-child,
div.remember h3:first-child,
div.remember h4:first-child {
    background: #f9f9FF;
    color: #566b78 !important;
}

div.myth {
    /*background: lavenderblush;*/
    /*color: brown;*/
    /*background-color: rgba(255, 255, 255, 0);*/
    /* background-image:         linear-gradient(top, lavenderblush 0%, rgba(255, 255, 255, 0) 80px); */ /* W3C */
    /* background-image:    -moz-linear-gradient(top, lavenderblush 0%, rgba(255, 255, 255, 0) 80px); */ /* FF3.6+ */
    /* background-image: -webkit-linear-gradient(top, lavenderblush 0%, rgba(255, 255, 255, 0) 80px); */ /* Chrome10+,Safari5.1+ */

    -webkit-box-shadow: 10px 10px 40px 0px rgba(165, 42, 42, 0.1);
       -moz-box-shadow: 10px 10px 40px 0px rgba(165, 42, 42, 0.1);
            box-shadow: 10px 10px 40px 0px rgba(165, 42, 42, 0.1);
    border-color: brown;
}

div.myth h1:first-child,
div.myth h2:first-child,
div.myth h3:first-child,
div.myth h4:first-child {
    background: lavenderblush;
    color: brown !important;
}

div.hypothesis {
    /*background: cornsilk;*/
    /*color: maroon;*/
    -webkit-box-shadow: 10px 10px 40px 0px rgba(128, 0, 0, 0.1);
       -moz-box-shadow: 10px 10px 40px 0px rgba(128, 0, 0, 0.1);
            box-shadow: 10px 10px 40px 0px rgba(128, 0, 0, 0.1);
    border-color: maroon;
}

div.hypothesis h1:first-child,
div.hypothesis h2:first-child,
div.hypothesis h3:first-child,
div.hypothesis h4:first-child {
    background: cornsilk;
    color: maroon !important;
}

div.theory {
    /*background: azure;*/
    /*color: darkblue;*/
    -webkit-box-shadow: 10px 10px 40px 0px rgba(0, 0, 139, 0.1);
       -moz-box-shadow: 10px 10px 40px 0px rgba(0, 0, 139, 0.1);
            box-shadow: 10px 10px 40px 0px rgba(0, 0, 139, 0.1);
    border-color: darkblue;
}

div.theory h1:first-child,
div.theory h2:first-child,
div.theory h3:first-child,
div.theory h4:first-child {
    background: azure;
    color: darkblue !important;
}

div.fact {
    /*background: honeydew;*/
    /*color: darkgreen;*/
    -webkit-box-shadow: 10px 10px 40px 0px rgba(1, 50, 32, 0.1);
       -moz-box-shadow: 10px 10px 40px 0px rgba(1, 50, 32, 0.1);
            box-shadow: 10px 10px 40px 0px rgba(1, 50, 32, 0.1);
    border-color: darkgreen;
}

div.fact h1:first-child,
div.fact h2:first-child,
div.fact h3:first-child,
div.fact h4:first-child {
    background: honeydew;
    color: darkgreen !important;
}

div.subtle {
    /*background: #f9f9f9;*/
    /*color: #999;*/
    -webkit-box-shadow: 10px 10px 40px 0px rgba(153, 153, 153, 0.1);
       -moz-box-shadow: 10px 10px 40px 0px rgba(153, 153, 153, 0.1);
            box-shadow: 10px 10px 40px 0px rgba(153, 153, 153, 0.1);
    border-color: #999;
}

div.subtle h1:first-child,
div.subtle h2:first-child,
div.subtle h3:first-child,
div.subtle h4:first-child {
    background: #f9f9f9;
    color: #999 !important;
}

.p-like {
    float: left;
    width: 100%;
    line-height: 1.5;
    margin: 16px 0;
    p + & {
        margin-top: unset;
    }
}

div.image-frame {
    border: 1px solid #CCC;
    background: #f8f9fa;
    padding: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.image-frame > .as-img,
div.image-frame > img {
    border: 1px solid #CCC;
    padding: 1px;
}

div.image-frame > small {
    width: 100%;
    border-top-width: 0;
    padding: 3px 6px 1px 6px;
    font-family: sans-serif;
    color: #333;
    font-style: italic;
    strong {
        font-family: AltGeorgiaBoldItalic;
    }
    &.regular {
        font-style: unset;
    }
    &.mono {
        font-family: monospace;
    }
}

sup a {
    text-decoration: none;
}

sup a:hover {
    color: maroon;
}

dl.news {
    padding: 0 0 0 110px;
}

dl.news dt {
    position: absolute;
    left: 0;
    width: 110px;
    text-align: right;
    color: #333;
    padding: 10px 10px 10px 0;
    border-top: 1px solid #DDD;
}

dl.news dd {
    margin: 0;
    padding: 10px 0 10px 10px;
    border-top: 1px solid #DDD;
}

footer {
    border-top: 1px solid grey;
    margin: 20px 0 20px 0;
    display: block;
    float: left;
}

footer h1 {
    font-size: 1em;
}

dl.references {
    position: relative;
    padding-left: 30px;
}

dl.references dt {
    position: absolute;
    left: 0;
    width: 30px;
    text-align: right;
    font-size: 0.8em;
    padding: 2px 3px 0 0;
    font-weight: bold;
    color: #e81c4f;
}

dl.references dd {
    margin: 0;
    left: 30px;
}

dl.references dd .subsection {
    font-size: 0.8em;
    font-weight: bold;
    color: #e81c4f;
}

dl.references dd a {
    color: inherit;
    text-decoration: none;
}

dl.references dd a:hover {
    text-decoration: underline;
}

dl.references dd span,
dl.references dd em {
    color: inherit;
}

div.help {
    float: left;
    width: 100%;
    background: #f9f9FF;
    color: #566b78;
    border: 1px solid #566b78;
    padding: 10px;
    margin-top: 20px;
}

.references .ref {
    display: flex;
    padding-left: 3px;
}

.references .ref > .subsection + * {
    padding-left: 5px;
}

a.hide {
    color: unset;
    font-style: unset;
    text-decoration: unset;
}

.travolta {
    width: 800px;
    height: 600px;
    background: url("../images/illustrations/Ship.jpg");
    position: relative;
    img {
        position: absolute;
        bottom: 0;
        left: 150px;
    }
}
