a.citation {
    font-size: 0.9em;
    vertical-align: super;
    text-decoration: none;
    text-transform: lowercase;
    line-height: initial;
    &:hover {
        color: #a71136;
    }
}

ol.bibliography {
    display: block;
    float: left;
    list-style: none;
    padding: 0;
    li {
        display: block;
        float: left;
        clear: both;
        position: relative;
        span.idx {
            display: block;
            float: left;
            position: absolute;
            top: 0;
            left: 0;
            text-align: right;
            width: 22px;
            color: #e81c4f;
            font-size: 0.8em;
            font-weight: bold;

            &:after {
                content: ".";
            }
        }
        span.idx + span {
            display: block;
            padding: 0 0 0 26px;
            float: left;
            clear: both;
            font-size: 0.9em;
            a {
                color: #1181a7;
                // color: #16a5d5;
                &:hover {
                    color: #e81c4f;
                }
            }
        }
    }
}